<template>
  <div class="container">
    <navbar
      title="霄鸟云集采中心"
      :leftArrow="false"
    ></navbar>
    <div class="top-container">
      <div class="top-main">
        <div class="top-main-top">
          <van-image
            :width="$px2vw(100)"
            :height="$px2vw(100)"
            round
            class="top-head"
            :src="data.avatar"
          />
          <div class="top-name">{{data.nickname}}</div>
        </div>
        <div class="top-main-bottom">
          <div class="top-bottom-item" @click="$router.push('/collect')">
            <div class="top-bottom-item-num">{{data.collectCountSql}}</div>
            <div class="top-bottom-item-text">商品收藏</div>
          </div>
          <div class="top-bottom-item" @click="$router.push('/browserRecord')">
            <div class="top-bottom-item-num">{{data.browseCount}}</div>
            <div class="top-bottom-item-text">浏览记录</div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-1">
      <div class="block-1-title" @click="$router.push('/order')">
        <div class="block-1-title-left">我的订单</div>
        <div class="block-1-title-right">
          <img :src="require('@/assets/images/icon/arrow_right.png')" />
        </div>
      </div>
      <div class="block-1-content">
        <div class="block-1-content-item" @click="$router.push('/order/0')">
          <van-badge
            :content="data.unPayCount ? data.unPayCount : ''"
            color="#F33636"
          >
            <img
              class="block-1-content-item-icon"
              :src="require('@/assets/images/my/order_status_1.png')"
            />
          </van-badge>
          <div class="block-1-content-item-text">待付款</div>
        </div>
        <div class="block-1-content-item" @click="$router.push('/order/1')">
          <van-badge
            :content="data.payIngCount ? data.payIngCount : ''"
            color="#F33636"
          >
            <img
              class="block-1-content-item-icon"
              :src="require('@/assets/images/my/order_status_2.png')"
            />
          </van-badge>
          <div class="block-1-content-item-text">付款中</div>
        </div>
        <div class="block-1-content-item" @click="$router.push('/order/2')">
          <van-badge
            :content="data.payCount ? data.payCount : ''"
            color="#F33636"
          >
            <img
              class="block-1-content-item-icon"
              :src="require('@/assets/images/my/order_status_3.png')"
            />
          </van-badge>
          <div class="block-1-content-item-text">待发货</div>
        </div>
        <div class="block-1-content-item" @click="$router.push('/order/4')">
          <van-badge
            :content="data.waitCount ?  data.waitCount : ''"
            color="#F33636"
          >
            <img
              class="block-1-content-item-icon"
              :src="require('@/assets/images/my/order_status_4.png')"
            />
          </van-badge>
          <div class="block-1-content-item-text">待收货</div>
        </div>
        <div class="block-1-content-item" @click="$router.push('/order/5')">
          <van-badge
            color="#F33636"
          >
            <img
              class="block-1-content-item-icon"
              :src="require('@/assets/images/my/order_status_5.png')"
            />
          </van-badge>
          <div class="block-1-content-item-text">已完成</div>
        </div>
      </div>
    </div>
    <div class="block-2" v-if="(data.isMain && data.qualification) || data.applyStatus == 2">
      <div class="block-2-title">
        <div class="block-2-title-left">
          <div>授信管理</div>
          <div class="title-explain">&nbsp;(由中国邮政储蓄银行提供金融服务)</div>
        </div>
        <div
          class="block-2-title-right"
          v-if="data.applyStatus == 2"
          @click="$router.push('/credit/record')"
        >授信使用记录</div>
      </div>
      <div class="block-2-content">
        <!-- 显示额度 -->
        <div
          class="credit-show-limit"
          v-if="data.applyStatus == 2"
        >
          <img
            class="eye"
            v-if="creditShow"
            :src="require('@/assets/images/icon/eye_close.png')"
            @click="creditShow = false;"
          >
          <img
            class="eye"
            v-else
            :src="require('@/assets/images/icon/eye_open.png')"
            @click="creditShow = true;"
          >
          <div class="credit-show-limit-item">
            <div class="credit-show-limit-item-num">
              <template v-if="creditShow">
                <span class="limit-unit">￥</span>{{data.credit_line}}
              </template>
              <template v-else>****</template>
            </div>
            <div class="credit-show-limit-item-text">授信额度</div>
          </div>
          <div class="credit-show-limit-item">
            <div class="credit-show-limit-item-num">
              <template v-if="creditShow">
                <span class="limit-unit">￥</span>{{data.remaining}}
              </template>
              <template v-else>****</template>
            </div>
            <div class="credit-show-limit-item-text">剩余额度</div>
          </div>
        </div>
        <!-- 申请授信 -->
        <div
          class="apply-credit"
          v-else-if="data.applyStatus == 0"
        >
          <div class="credit-button" @click="$router.push('/credit/apply')">申请授信</div>
        </div>
        <!-- 授信审核 -->
        <div
          class="credit-auditing"
          v-else-if="data.applyStatus == 1"
        >
          <div class="auditing-explain">资料审核中</div>
          <div class="credit-button" @click="$router.push('/credit/apply')">查看</div>
        </div>
        <!-- 授信拒绝 -->
        <div
          class="credit-refuse"
          v-else-if="data.applyStatus == 3"
        >
          <div class="refuse-explain">资料审核不通过</div>
          <div class="credit-button" @click="$router.push('/credit/apply')">查看</div>
        </div>
      </div>
    </div>
    <div class="block-3">
      <div class="block-3-item" @click="$router.push('/payRecord')">
        <img
          class="block-3-item-icon"
          :src="require('@/assets/images/my/zfmx.png')"
        />
        <div class="block-3-item-text">支付明细</div>
      </div>
      <div class="block-3-item" @click="$router.push('/setting')">
        <img
          class="block-3-item-icon"
          :src="require('@/assets/images/my/zfsz.png')"
        />
        <div class="block-3-item-text">支付设置</div>
      </div>
      <div class="block-3-item" @click="$router.push('/invoice')">
        <img
          class="block-3-item-icon"
          :src="require('@/assets/images/my/fpgl.png')"
        />
        <div class="block-3-item-text">发票管理</div>
      </div>
      <div class="block-3-item" @click="$router.push('/address')">
        <img
          class="block-3-item-icon"
          :src="require('@/assets/images/my/dzgl.png')"
        />
        <div class="block-3-item-text">地址管理</div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import {userDetail} from '@/api/index.js'

export default {
  name: "my",
  components: {
    Navbar,
  },
  data() {
    return {
      creditShow: false,     //显示额度
      data: {
        isMain: 0,
        applyStatus: 0
      }
    };
  },
  mounted() {
    this.getData();
    document.title = '霄鸟云集采中心'
  },
  methods: {
    getData() {
      userDetail({type: ''})
        .then(res => {
          if (res.code == 0) {
            this.data = res.data
          }
        })
    }
  },
  computed: {

  },
};
</script>
<style lang="scss" scoped>
.container {
  .top-container {
    height: 400px;
    padding: 20px;
    box-sizing: border-box;
    background: url(~@/assets/images/my/bg.png);
    background-size: 100% 100%;
    .top-main {
      height: 300px;
      border-radius: 16px;
      width: 100%;
      background: url(~@/assets/images/my/bg1.png);
      background-size: 775px 300px;
      background-position: -35px 0px;
      display: flex;
      flex-direction: column;
      .top-main-top {
        height: 180px;
        display: flex;
        align-items: center;
        .top-head {
          border-radius: 50%;
          border: 5px solid #ffffff;
          margin-left: 35px;
          flex-shrink: 0;
        }
        .top-name {
          margin-left: 25px;
          font-size: 32px;
          font-weight: bold;
          color: #ffffff;
          margin-right: 40px;
          @include not-wrap;
        }
      }
      .top-main-bottom {
        flex: 1;
        display: flex;
        .top-bottom-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .top-bottom-item-num {
            font-size: 36px;
            font-weight: bold;
            color: #ffffff;
          }
          .top-bottom-item-text {
            margin-top: 5px;
            font-size: 28px;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
  }
  .block-1 {
    background: #ffffff;
    border-radius: 16px;
    margin-top: -59px;
    margin-left: 20px;
    margin-right: 20px;
    .block-1-title {
      height: 86px;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;
      .block-1-title-left {
        font-size: 30px;
        font-weight: bold;
        color: #333333;
      }
      .block-1-title-right {
        flex-shrink: 0;
        margin-left: 10px;
        img {
          width: 10px;
          height: 20px;
        }
      }
    }
    .block-1-content {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 33px;
      .block-1-content-item {
        text-align: center;
        padding-top: 30px;
        padding-bottom: 27px;
        font-size: 0;
        .block-1-content-item-icon {
          height: 48px;
          width: 48px;
        }
        .block-1-content-item-text {
          margin-top: 9px;
          font-size: 28px;
          font-weight: 500;
          color: #4f4f4f;
        }
      }
    }
  }
  .block-2 {
    background: #ffffff;
    border-radius: 16px;
    margin: 20px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    .block-2-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 82px;
      padding-left: 30px;
      padding-right: 20px;
      box-sizing: border-box;
      .block-2-title-left {
        font-size: 30px;
        font-weight: bold;
        color: #333333;
        display: flex;
        align-items: center;
        .title-explain {
          font-size: 22px;
          font-weight: 400;
          color: #b2cadc;
        }
      }
      .block-2-title-right {
        font-size: 24px;
        font-weight: 500;
        color: #1aa3ff;
      }
    }
    .block-2-content {
      height: 180px;
      margin: 20px;
      margin-top: 0;
      background: url(~@/assets/images/my/bg2.png);
      background-size: 100% 100%;
    }
    .credit-show-limit {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      .eye {
        position: absolute;
        right: 28px;
        top: 24px;
        width: 20px;
      }
      .credit-show-limit-item {
        text-align: center;
        width: 50%;
        height: 88px;
        color: #01395e;
        flex-shrink: 0;
        &:last-child {
          border-left: 1px solid rgba($color: #074168, $alpha: 0.09);
        }
        .credit-show-limit-item-num {
          font-size: 40px;
          font-weight: bold;
          .limit-unit {
            font-size: 28px;
          }
        }
        .credit-show-limit-item-text {
          margin-top: 4px;
          font-size: 28px;
          font-weight: 400;
        }
      }
    }
    .apply-credit {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .credit-auditing {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .auditing-explain {
        font-size: 28px;
        font-weight: bold;
        color: #4f4f4f;
        margin-bottom: 14px;
      }
    }

    .credit-refuse {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .refuse-explain {
        font-size: 28px;
        font-weight: bold;
        color: #FF500F;
        margin-bottom: 14px;
      }
    }

    .credit-button {
      width: 330px;
      height: 70px;
      background: #1aa3ff;
      border-radius: 41px;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 28px;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .block-3 {
    background: #ffffff;
    border-radius: 16px;
    margin: 20px;
    padding: 35px 53px 32px 53px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .block-3-item {
      text-align: center;
      font-size: 0;
      .block-3-item-icon {
        height: 45px;
      }
      .block-3-item-text {
        margin-top: 14px;
        font-size: 24px;
        font-weight: 500;
        color: #4f4f4f;
      }
    }
  }
}
</style>
